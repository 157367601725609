<template>
  <div>
    <div class="title">
      <img
        src="@/assets/Img/login/icon/DL_0005_fanhui.png"
        @click="handleClose"
      />
      <p class="versions">{{ $t('Login.Forgot.title') }}</p>
    </div>
    <div class="cont">
      <div class="form" v-for="(item, index) in formList" :key="index">
        <input
          :type="item.type"
          :placeholder="item.label"
          :maxlength="item.length"
          v-model="form[item.key]"
        />
        <button v-if="item.key === 'code' && isSendCodeClick" @click="sendCode">
          {{ $t('Login.Forgot.sendCode') }}
        </button>
        <button v-if="item.key === 'code' && !isSendCodeClick">
          {{ auth_time }} s
        </button>
        <img
          v-if="item.key === 'password' || item.key === 'againPassword'"
          src="@/assets/Img/login/icon/DL_0002_biyan.png"
          @click="handleLookPass(item)"
        />
      </div>
      <div class="btn-box">
        <button @click="handleConfirm">{{ $t('Login.Forgot.confirm') }}</button>
        <button @click="handleClose">{{ $t('Login.Forgot.goBack') }}</button>
      </div>
    </div>
  </div>
</template>

<script>
import {
  Row,
  Col,
  Icon,
  Cell,
  GridItem,
  Grid,
  NavBar,
  ActionSheet,
  Button,
  Toast,
} from 'vant'

// 设置vant的多语言
import Cookies from 'js-cookie'
import { Locale } from 'vant'
import enUS from 'vant/lib/locale/lang/en-US'
import { REQUEST_API } from '@/http/api'
import { getNewPhone } from '@/utils/tools'
import Modelu from '@/utils/Module'

export default {
  data() {
    return {
      form: {
        uesrName: '',
        code: '',
        password: '',
        againPassword: '',
      },
      formList: [
        {
          label: `${this.$t('Login.Forgot.area')}`,
          type: 'number',
          key: 'Area',
          length: 5,
        },
        {
          label: `${this.$t('Login.Forgot.phone')}`,
          type: 'number',
          key: 'uesrName',
          length: 99,
        },
        {
          label: `${this.$t('Login.Forgot.code')}`,
          type: 'text',
          key: 'code',
          length: 6,
        },
        {
          label: `${this.$t('Login.Forgot.password')}`,
          type: 'password',
          key: 'password',
          length: 99,
        },
        {
          label: `${this.$t('Login.Forgot.againPassword')}`,
          type: 'password',
          key: 'againPassword',
          length: 99,
        },
      ],
      isSendCodeClick: true,
      auth_time: 0,
    }
  },
  components: {
    [Row.name]: Row,
    [Col.name]: Col,
    [Icon.name]: Icon,
    [Cell.name]: Cell,
    [GridItem.name]: GridItem,
    [Grid.name]: Grid,
    [NavBar.name]: NavBar,
    [ActionSheet.name]: ActionSheet,
    [Button.name]: Button,
  },
  created() {},
  methods: {
    /** handle **/
    sendCode() {
      if (!this.form.phone.length) {
        Toast(this.$t('Hint.phoneNot')) // 手机号/账号不能为空
        return false
      }
      let form = {
        phone: getNewPhone(this.form.phone),
        type: 1,
        cmd: '100002',
        channel: Modelu.signStrFunc(),
        xsend: Modelu.getXsend(),
      }
      console.log(form)
      REQUEST_API(form)
        .then((r) => {
          if (!r.data.sc) {
            Toast(this.$t('Hint.success'))
            this.isSendCodeClick = false
            this.auth_time = 60
            let auth_timetimer = setInterval(() => {
              this.auth_time--
              if (this.auth_time <= 0) {
                this.isSendCodeClick = true
                clearInterval(auth_timetimer)
              }
            }, 1000)
          } else {
            Toast(this.$t('Hint.failed'))
          }
          console.log('r', r)
        })
        .catch((e) => {
          console.log(e)
        })
    },
    handleClose() {
      this.$router.go(-1)
    },
    handleConfirm() {
      let list = ['phone', 'code', 'password', 'againPassword']
      let count = 0
      list.forEach((_) => {
        if (this.form[_]) {
          count++
        }
      })
      if (this.form.password !== this.form.againPassword) {
        Toast(this.$t('Hint.pwdInconformity')) // 密码不一致
      } else if (count < 3) {
        Toast(this.$t('Hint.notInput')) // 输入内容不完整
      } else {
        this.forgot()
      }
    },
    handleLookPass(item) {
      this.formList.forEach((_) => {
        if (item.key === _.key) {
          if (_.type === 'password') {
            _.type = 'text'
          } else {
            _.type = 'password'
          }
        }
      })
    },
    /** api **/
    forgot() {
      console.log(this.form)
    },
  },
}
</script>

<style lang="less" scoped>
.title {
  height: calc(45rem / 16);
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: calc(14rem / 16);
  position: relative;
  > img {
    width: calc(18rem / 16);
    position: absolute;
    top: 50%;
    left: calc(20rem / 16);
    transform: translate(0, -50%);
    z-index: 9;
  }
}
.cont {
  // width: calc(295rem / 16);
  width: 90%;
  margin: auto;
  box-shadow: 0 0.053333rem 0.533333rem 0.053333rem #dfdfdf;
  padding: calc(10rem / 16);
  border-radius: 6px;
  margin-top: calc(10rem / 16);
  > .form {
    width: 100%;
    height: calc(40rem / 16);
    border-radius: 25px;
    border: calc(1rem / 16) solid #eee;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: calc(15rem / 16);
    > input {
      padding-left: calc(15rem / 16);
      font-size: calc(14rem / 16);
      border: 0;
    }
    > button {
      // width: calc(77rem / 16);
      height: calc(22rem / 16);
      font-size: calc(12rem / 16);
      color: #fff;
      background: red;
      border-radius: 5px;
      border: 0;
      margin-right: calc(15rem / 16);
    }
    > img {
      width: calc(30rem / 16);
      margin-right: calc(15rem / 16);
    }
  }
  > .btn-box {
    display: flex;
    flex-direction: column;
    > button {
      width: 100%;
      height: calc(40rem / 16);
      border-radius: 25px;
      border: 0;
      font-size: calc(18rem / 16);
    }
    > button:nth-child(1) {
      background: #ec0022;
      color: #fff;
      margin-bottom: calc(15rem / 16);
    }
    > button:nth-last-child(1) {
      background: #fff;
      color: #ec0022;
      border: calc(1rem / 16) solid #ec0022;
    }
  }
}
</style>
